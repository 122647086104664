import { Fragment, FunctionComponent } from 'react';

import { Rectangle } from '@/icons';

import { TypeInfoProps } from './types';

interface TypeExtendedProps extends TypeInfoProps {
  arrClean: string[];
}
export const Info: FunctionComponent<TypeInfoProps> = ({
  className = '',
  info,
  arrClean= info.filter(Boolean)
}: TypeExtendedProps): JSX.Element => (
  <div className="flex items-center gap-2">
    {arrClean?.map((category: string, index: number) => {
      if (category != 'null') {
        return (<Fragment key={index}>
          <p className={`text-caption text-caption--m ${className}`}>
            {category}
          </p>

          {index === info.length - 1 ? null : (
            <Rectangle
              color="rgb(var(--tw-color-primary-500))"
              width={6}
              height={6}
            />
          )}
        </Fragment>)
      }
    })}
  </div>
);
