import { FunctionComponent } from 'react';
import { oneLine } from 'common-tags';

import { Pretitle } from './childs';
import { Container } from '@/atoms';

import { toArray } from '@/lib/utils';

import { TypeHeroProps } from './types';

/**
 * Hero
 */
export const Hero: FunctionComponent<TypeHeroProps> = ({
  className = '',
  classNameWrapper = '',
  backgroundImage,
  backgroundTop,
  backgroundColor,
  backgroundVideo,
  children,
  overlay,
  justify,
  textAlign,
  theme,
  align,
  minHeight,
  backgroundBottom,
  notLazy = true,
  style,
}: TypeHeroProps): JSX.Element => (
  <Container
    wrapper
    className={className}
    classNameWrapper={oneLine`
      ${classNameWrapper}
      ${theme === 'light' ? 'text-black' : 'text-white'}
    `}
    backgroundImage={backgroundImage}
    backgroundColor={backgroundColor}
    backgroundVideo={backgroundVideo}
    justify={justify}
    align={align}
    textAlign={textAlign}
    overlay={overlay}
    backgroundTop={backgroundTop}
    backgroundBottom={backgroundBottom}
    backgroundLazy={notLazy}
    style={{
      ...style,
      ...(minHeight
        ? {
            minHeight: `${minHeight}px`,
          }
        : {}),
    }}
  >
    {toArray(children)
      .filter(Boolean)
      .map((child, index) => (
        <child.type
          {...(child.type === Pretitle ? { theme: theme } : {})}
          {...child.props}
          key={index}
        />
      ))}
  </Container>
);

Hero.displayName = 'Hero';
