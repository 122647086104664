import { Hero } from './Hero';

import { Button, Description, Image, Link, Pretitle, Title } from './childs';

export type TypeHero = typeof Hero & {
  Pretitle: typeof Pretitle;
  Title: typeof Title;
  Description: typeof Description;
  Image: typeof Image;
  Link: typeof Link;
  Button: typeof Button;
};

(Hero as TypeHero).Pretitle = Pretitle;
(Hero as TypeHero).Title = Title;
(Hero as TypeHero).Description = Description;
(Hero as TypeHero).Image = Image;
(Hero as TypeHero).Link = Link;
(Hero as TypeHero).Button = Button;
(Hero as TypeHero).Pretitle = Pretitle;

Pretitle.displayName = 'Hero.Pretitle';
Title.displayName = 'Hero.Title';
Description.displayName = 'Hero.Description';
Image.displayName = 'Hero.Image';
Link.displayName = 'Hero.Link';
Button.displayName = 'Hero.Button';

export type { TypeHeroProps } from './types';
export type {
  TypeButtonLink,
  TypeTextProps,
  TypeImageComplete,
  TypeUnderlineTagProps,
  TypeLinkProps,
} from './childs/types';

export default Hero as TypeHero;
