import { FunctionComponent } from 'react';
import Link from 'next/link';

import { Button as ButtonAtom } from '@/atoms';

import { ButtonVariant, TypeButtonLink } from './types';

export const Button: FunctionComponent<TypeButtonLink> = ({
  variant = ButtonVariant.PRIMARY,
  href,
  children,
  outline,
  external,
  margin = false,
}: TypeButtonLink) => (
  <div className={`flex mt-auto md:mt-0 md:mb-0 ${margin ? 'mb-2' : ''}`}>
    {external ? (
      <ButtonAtom
        as="a"
        variant={variant}
        outline={outline}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </ButtonAtom>
    ) : (
      <Link href={href} prefetch={false} passHref legacyBehavior>
        <ButtonAtom as="a" variant={variant} outline={outline}>
          {children}
        </ButtonAtom>
      </Link>
    )}
  </div>
);
