import { FunctionComponent } from 'react';
import { default as LinkNext } from 'next/link';

import { Link as LinkAtom } from '@/atoms';

import { TypeLinkProps } from './types';

export const Link: FunctionComponent<TypeLinkProps<'a'>> = ({
  className = '',
  href,
  children,
  variant,
}: TypeLinkProps<'a'>): JSX.Element => (
  <LinkNext href={href} passHref prefetch={false} legacyBehavior>
    <LinkAtom
      variant={variant}
      as="a"
      icon="right"
      className={`m-auto ${className} mb-[72px] md:mb-[105px]`}
    >
      {children}
    </LinkAtom>
  </LinkNext>
);
