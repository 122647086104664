import { FunctionComponent } from 'react';
import { TypeTextProps } from '@/molecules/hero';
import { ParseText } from '@/utils/parseText';

export const Description: FunctionComponent<TypeTextProps> = ({
  children
}: TypeTextProps) => (
  <>
    <div className="text-body text-body--l mb-8 md:w-[590px]"><ParseText html={children} /></div>
  </>
);
