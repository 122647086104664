import { FunctionComponent } from 'react';

import { TypeTextProps } from './types';

export const Description: FunctionComponent<TypeTextProps> = ({
  className = '',
  children,
}: TypeTextProps): JSX.Element => (
  <p className={`text-body text-body--m line-clamp-2 ${className}`}>
    {children}
  </p>
);
