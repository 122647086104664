import { FunctionComponent } from 'react';
import { Options } from '@splidejs/splide';
import { Splide } from '@splidejs/react-splide';

import { toArray } from '@/lib/utils';

import { StylePagination, TypeSliderHeroProps } from './types';

import '@splidejs/react-splide/css/core';

const STYLE_MAPS: Record<StylePagination, string> = {
    [StylePagination.PAGINATION]: `flex flex-nowrap gap-3 bottom-10 md:bottom-32 md:right-24 flex justify-center py-0 px-4 relative md:w-full md:max-w-[calc(var(--wrapper-size)+1rem)] mx-auto md:justify-end`,
    [StylePagination.PAGE]: `before:w-1 before:h-1 p-3 relative before:bottom-2.5 before:right-2.5 before:rotate-45 before:bg-primary-500 before:content:[''] before:absolute [&.is-active]:after:border-primary-500 [&.is-active]:after:content-[''] [&.is-active]:after:border [&.is-active]:after:rounded-full [&.is-active]:after:p-3 [&.is-active]:after:absolute [&.is-active]:after:top-1/2 [&.is-active]:after:left-1/2 [&.is-active]:after:transform [&.is-active]:after:-translate-y-1/2 [&.is-active]:after:-translate-x-1/2`,
};

/**
 * SliderHero
 */
export const SliderHero: FunctionComponent<TypeSliderHeroProps> = ({
    className = '',
    minHeight = '',
    children,
}: TypeSliderHeroProps) => {
    const childrenArray = toArray(children);
    const options: Options = {
        type: childrenArray.length === 1 ? 'slide' : 'loop',
        perPage: 1,
        perMove: 1,
        pagination: childrenArray.length > 1,
        arrows: false,
        classes: {
            pagination: `${STYLE_MAPS[StylePagination.PAGINATION]}`,
            page: `${STYLE_MAPS[StylePagination.PAGE]}`,
        },
        lazyLoad: 'sequential',
        autoplay: true,
        clones: 0,
        /**
         * Important! if the speed is changed you mush change the
         * CSS transition speed to the same value
         */
        speed: 500,
    };
    return (
        <Splide options={options} className={className}>
            {childrenArray.map((child, index) => (
                <child.type
                    {...child.props}
                    className={`${minHeight} ${child.props?.className ?? ''}`}
                    key={index}
                />
            ))}
        </Splide>
    );
};

SliderHero.displayName = 'SliderHero';
