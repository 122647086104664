import { FunctionComponent } from 'react';
import { oneLine } from 'common-tags';

import { TypeUnderlineTagProps } from './types';

/**
 * UnderlineTag
 */
export const UnderlineTag: FunctionComponent<TypeUnderlineTagProps> = ({
  className = '',
  children,
  theme = 'dark',
  position,
}: TypeUnderlineTagProps) => (
  <div
    className={oneLine`
      text-caption text-caption--m tracking-[0.2rem]
      ${theme === 'light' ? 'text-text-primary' : 'text-text-alternative'}
      ${position === 'center' ? 'text-center' : ''}
      ${position === 'right' ? 'text-right' : ''}
      ${className}
    `}
  >
    {children}

    <div
      className={oneLine`
        flex
        ${position === 'center' ? 'justify-center' : ''}
        ${position === 'right' ? 'justify-end' : ''}
      `}
    >
      <hr
        className={oneLine`
          mt-4 w-6
          ${theme === 'light' ? 'border-primary-500' : 'border-primary-50'}
        `}
      />
    </div>
  </div>
);

UnderlineTag.displayName = 'UnderlineTag';
