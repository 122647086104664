import { Text } from '@/atoms';
import { FunctionComponent } from 'react';

import { TypeTitleProps } from './types';

export const Title: FunctionComponent<TypeTitleProps> = ({
  children,
  className = '',
  as = 'p',
}: TypeTitleProps) => (
  <Text
    as={as}
    className={`text-headline text-headline--h1 md:text-display mb-6 w-full  ${className}`}
  >
    {children}
  </Text>
);
