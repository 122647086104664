import { FunctionComponent } from 'react';

import { SplideSlide } from '@splidejs/react-splide';

import { TypeSlideProps } from './types';

/**
 * Slide
 */
export const Slide: FunctionComponent<TypeSlideProps> = ({
  className = '',
  children,
}: TypeSlideProps) => (
  <SplideSlide className={`w-full ${className}`}>
    <children.type
      {...children.props}
      className={`h-full ${children.props.className ?? ''}`}
      classNameWrapper={`pb-7 md:pb-0 ${children.props.classNameWrapper ?? ''}`}
    />
  </SplideSlide>
);
