import { IconType, defaultProps } from './types';

/**
 * Icono Rectangle
 */
export const Rectangle = ({
  className,
  width,
  height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className ? { className: className } : {})}
    {...(width ? { width: width } : {})}
    {...(height ? { height: height } : {})}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 6 6"
  >
    <path d="M0 3 2.828.172 5.657 3 2.828 5.828z" fill={color} />
  </svg>
);
