import { FunctionComponent } from 'react';
import Image from 'next/image';
import { oneLine } from 'common-tags';

import * as childs from './childs';

import { getUrlExtension, isPNG, pickChild } from '@/lib/utils';

import FallBackIMG from '@/assets/images/fallback-product-card.jpg';

import { Size, TypeCardBlogProps, Variation } from './types';
import Link  from 'next/link';

const SIZE_CARD: Record<Size, string> = {
  [Size.SMALL]: 'h-full gap-x-6',
  [Size.MEDIUM]: 'h-full flex-col ',
  [Size.LARGE]: 'flex-row-reverse',
};

const SIZE_IMAGE: Record<Size, string> = {
  [Size.SMALL]: 'h-[130px] w-[115px]',
  [Size.MEDIUM]: 'h-[160px]',
  [Size.LARGE]: 'min-h-[280px] w-1/2',
};

const SIZE_CONTENT: Record<Size, string> = {
  [Size.SMALL]: 'justify-end',
  [Size.MEDIUM]: '',
  [Size.LARGE]: 'w-1/2 pt-10',
};

const VARIATIONS_MAPS: Record<Variation, string> = {
  [Variation.DEFAULT]:
    'bg-white text-secondary-900 border-b border-secondary-200',
  [Variation.DISPLAY]: 'bg-primary-500 text-white',
  [Variation.DARK]: 'bg-secondary-900 text-white',
};

/**
 * CardBlog
 */
export const CardBlog: FunctionComponent<TypeCardBlogProps> = ({
  image,
  children,
  size = Size.MEDIUM,
  variation = Variation.DEFAULT,
  className = '',
}: TypeCardBlogProps): JSX.Element => {
  const [, description] = pickChild(children, childs.Description);
  const [, info] = pickChild(children, childs.Info);
  const [, link] = pickChild(children, childs.Link);
  const [, pretitle] = pickChild(children, childs.Pretitle);
  const [, title] = pickChild(children, childs.Title);
  const srcWithFallback =
    image.src && image.src !== '' ? image.src : FallBackIMG.src;

  return (
    <div
      className={`flex ${SIZE_CARD[size]} ${VARIATIONS_MAPS[variation]} ${className}`}
    >
      <div className={`relative flex-shrink-0 ${SIZE_IMAGE[size]}`}>
         <Link href={link.props.href} prefetch={false} aria-label="Leer más">
        <Image
          src={srcWithFallback}
          alt={image.alt ?? 'Card image'}
          className={oneLine`
            w-full h-full object-center absolute inset-0
            ${isPNG(getUrlExtension(srcWithFallback))
              ? 'object-contain'
              : 'object-cover'
            }
          `}
          draggable="false"
          sizes="(max-width: 500px) 100vw,
            (max-width: 768px) 50vw,
            (max-width: 1024px) 33vw,
            25vw"
          fill
        /> </Link>
      </div>

      <div
        className={oneLine`
          flex flex-col gap-4 p-4 w-full h-full
          ${Variation.DEFAULT === variation ? 'px-0' : ''}
          ${SIZE_CONTENT[size]}
        `}
      >
        {pretitle && (
          <pretitle.type
            theme={Variation.DEFAULT === variation ? 'light' : 'dark'}
            {...pretitle.props}
          />
        )}

        {info}

        <div className="flex flex-col gap-2 flex-grow">
          {title}
          {description}
        </div>

        {link && (
          <link.type
            className={Size.LARGE === size ? 'mt-auto' : ''}
            variant={Variation.DEFAULT === variation ? 'POSITIVE' : 'NEGATIVE'}
            {...link.props}
          />
        )}
      </div>
    </div>
  );
};

CardBlog.displayName = 'CardBlog';
