import { FunctionComponent } from 'react';
import { oneLine } from 'common-tags';

import { Link, Text, UnderlineTag } from '@/atoms';
import { Prose } from '@/molecules';

import { TypeContentProps } from './types';
import { LinkVariant } from '@/atoms/link/types';

/**
 * Content
 */
export const Content01: FunctionComponent<TypeContentProps> = ({
  className = '',
  pretitle,
  title,
  description,
  link,
  theme = 'light',
  position = 'left',
  typeTitle = 'h2',
}: TypeContentProps) => (
  <div
    className={oneLine`
      flex flex-col
      ${position === 'left' ? 'items-start' : ''}
      ${position === 'center' ? 'items-center text-center' : ''}
      ${position === 'right' ? 'items-end' : ''}
      ${theme === 'light' ? 'text-text-primary' : ''}
      ${theme === 'dark' ? 'text-text-alternative' : ''}
      ${className}
    `}
  >
    {pretitle && (
      <UnderlineTag
        theme={theme}
        position={position}
        className="mb-4 last:mb-0"
      >
        {pretitle}
      </UnderlineTag>
    )}

    {title && (
      <Text
        className="text-headline text-headline--h2 mb-6 last:mb-0"
        as={typeTitle}
      >
        {title}
      </Text>
    )}

    {description && (
      <Prose
        className="text-body text-lg md:text-body--l md:leading-[28px] mb-4 last:mb-0"
        html={description}
      />
    )}

    {link && (
      <Link
        href={link.href}
        icon="right"
        variant={theme === 'dark' ? LinkVariant.NEGATIVE : LinkVariant.POSITIVE}
      >
        {link.label}
      </Link>
    )}
  </div>
);

Content01.displayName = 'Content';
