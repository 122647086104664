import { FunctionComponent } from 'react';

import { UnderlineTag } from '@/atoms';

import { TypeUnderlineTagProps } from './types';

export const Pretitle: FunctionComponent<TypeUnderlineTagProps> = ({
  className = '',
  children,
  position,
  theme = 'light',
}: TypeUnderlineTagProps): JSX.Element => (
  <UnderlineTag theme={theme} position={position} className={className}>
    {children}
  </UnderlineTag>
);
