import { UnderlineTag } from '@/atoms';
import { FunctionComponent } from 'react';
import { TypeUnderlineTagProps } from './types';

export const Pretitle: FunctionComponent<TypeUnderlineTagProps> = ({
  children,
  theme = 'dark',
  position,
}: TypeUnderlineTagProps) => (
  <UnderlineTag theme={theme} className="mb-4" position={position}>
    {children}
  </UnderlineTag>
);
