import { CardBlog } from './CardBlog';

import * as childs from './childs';

export type TypeCardBlog = typeof CardBlog & {
  Description: typeof childs.Description;
  Info: typeof childs.Info;
  Link: typeof childs.Link;
  Pretitle: typeof childs.Pretitle;
  Title: typeof childs.Title;
};

(CardBlog as TypeCardBlog).Description = childs.Description;
(CardBlog as TypeCardBlog).Info = childs.Info;
(CardBlog as TypeCardBlog).Link = childs.Link;
(CardBlog as TypeCardBlog).Pretitle = childs.Pretitle;
(CardBlog as TypeCardBlog).Title = childs.Title;

childs.Description.displayName = 'CardBlog.Description';
childs.Info.displayName = 'CardBlog.Info';
childs.Link.displayName = 'CardBlog.Link';
childs.Pretitle.displayName = 'CardBlog.Pretitle';
childs.Title.displayName = 'CardBlog.Title';

export type { TypeCardBlogProps } from './types';
export type {
  TypeTextProps,
  TypeUnderlineTagProps,
  TypeInfoProps,
  TypeLinkProps,
} from './childs/types';

export default CardBlog as TypeCardBlog;
