import { TypeChildren, TypeClassName, TypeImage } from '@/types';

export const enum Size {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}
export const enum Variation {
  DEFAULT = 'DEFAULT',
  DISPLAY = 'DISPLAY',
  DARK = 'DARK',
}

export interface TypeCardBlogProps extends TypeClassName, TypeChildren {
  /**
   * imagen del card
   */
  image: TypeImage;
  /**
   * Indica el tipo de Card a mostrar
   */
  size: Size;
  /**
   * Indica el tipo de color a mostrar
   */
  variation: Variation;
}
