import { ReactElement, ReactNode } from 'react';

import { TypeClassName } from '@/types';

export const enum StylePagination {
  PAGE = 'PAGE',
  PAGINATION = 'PAGINATION',
}

export interface TypeSlideProps extends TypeClassName {
  /**
   * ¿Es el fondo oscuro o claro?
   */
  negative?: boolean;
  /**
   * Caption del slide
   */
  caption?: string;
  /**
   * Permite pasar el contenido.
   */
  children: ReactElement;
}

export interface TypeSliderHeroProps extends TypeClassName {
  /**
   * Tamaño minimo, ej: min-h-[600px]
   */
  minHeight?: string;
  /**
   * Permite pasar las slides.
   */
  children: ReactNode | ReactNode[];
}
