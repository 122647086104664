import { FunctionComponent } from 'react';

import { TypeTextProps } from './types';

export const Title: FunctionComponent<TypeTextProps> = ({
  className = '',
  children,
}: TypeTextProps): JSX.Element => (
  <h3 className={`text-subtitle text-subtitle--s line-clamp-2 ${className}`}>
    {children}
  </h3>
);
